<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <accion-ejec-form
          :id="routeParams.idaccion_ejec"
          :idparte-trabajo-matsist="routeParams.idparte_trabajo_matsist"
          :has-perm-modificar-material-facturable="hasViewPerm(permissions.partes.modificarMaterialFacturable)"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import AccionEjecForm from '../components/AccionEjecForm'
import Data from './AccionEjecEditData'

export default {
  components: {
    AccionEjecForm
  },
  mixins: [formPageMixin],
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Modificar acción'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      await Data.update(
        this,
        this.formData.id,
        this.routeParams.idparte_trabajo,
        this.formData,
      )
      this.$appRouter.go(-1)
    },
  },
}
</script>
